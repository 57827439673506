import { cdnImage } from "canopy-styleguide!sofe";

// NOTE if you need to add an option that does not need to use permissions
// use the noPermissions part of the object and omit `hasPermission` and `permission`
export const getIntegrationsOptions = (transcriptsOnly: boolean, showZapier: boolean) => [
  {
    logo: "https://cdn.canopytax.com/images/microsoft-windows.jpg",
    logoHeight: 60,
    logoAltText: "Microsoft Windows logo",
    name: "Desktop Assistant (Windows)",
    description: "Print, scan and edit files in Canopy.",
    hasPermission: {
      info: "Learn more",
      infoLink: "https://www.getcanopy.com/desktop-assistant",
      buttonText: "Download",
      action:
        "https://cdn.canopytax.com/cda/Canopy%20Desktop%20Assistant%20Installer.msi",
    },
    noPermission: {
      info: "Subscription update needed",
      buttonText: "Chat with support",
      action: "canopy-intercom-launcher",
    },
    permission: "hasDesktopAssistant",
  },
  {
    logo: cdnImage("apple-3d.png"),
    logoHeight: 60,
    logoAltText: "Apple logo",
    name: "Desktop Assistant (MacOS)",
    description: "Print, scan and edit files in Canopy.",
    hasPermission: {
      info: "Learn more",
      infoLink: "https://www.getcanopy.com/desktop-assistant",
      buttonText: "Download",
      action:
        "https://s3.us-west-2.amazonaws.com/cdn.canopytax.com/canopy-desktop-assistant/Canopy+Desktop+Assistant+Installer.pkg",
    },
    noPermission: {
      info: "Subscription update needed",
      buttonText: "Chat with support",
      action: "canopy-intercom-launcher",
    },
    permission: "hasDesktopAssistant",
  },
  {
    logo: cdnImage("virtual-drive-color.png"),
    logoHeight: 60,
    logoAltText: "Canopy logo",
    name: "Virtual Drive (Windows)",
    description: "Manage files on your desktop as a mapped virtual drive.",
    hasPermission: {
      info: "Learn more",
      infoLink:
        "https://support.getcanopy.com/hc/en-us/articles/9126643262235-Install-the-Canopy-Virtual-Drive",
      buttonText: "Download",
      action:
        "https://odrive-deploy.s3.amazonaws.com/odrive_sync_prod_builds/latest/canopy_download_prod.exe",
    },
    noPermission: {
      info: "Subscription update needed",
      buttonText: "Chat with support",
      action: "canopy-intercom-launcher",
    },
    permission: "hasVirtualDrive",
  },
  {
    logo: cdnImage("o-drive.png"),
    logoHeight: 60,
    logoAltText: "odrive logo",
    name: "odrive",
    description:
      "Connect Canopy file storage to 20+ providers and handle advanced file workflow.",
    hasPermission: {
      buttonText: "Connect",
      action: "https://www.odrive.com/homepage5b",
    },
    noPermission: {
      info: "Subscription update needed",
      buttonText: "Chat with support",
      action: "canopy-intercom-launcher",
    },
    permission: "hasOdrive",
  },
  ...(!transcriptsOnly && showZapier 
    ? [
        {
          logo: cdnImage("zapier.png"),
          logoHeight: 40,
          logoAltText: "Zapier logo",
          name: "Zapier",
          description: "Connect client information to other softwares.",
          noPermission: {
            buttonText: "Connect",
            actionLink: "https://zapier.com/apps/canopy/integrations",
          },
        },
      ]
    : []),
];
