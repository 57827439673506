import React, { useLayoutEffect, useRef, useState } from "react";
import { useCss, k, a } from "kremling";

export default function ImportProgressBar(props) {
  const { max = 0, current = 0, importing = 0 } = props;

  const scope = useCss(css);

  const caretRef = useRef();
  const currentTextRef = useRef();
  const progressBarRef = useRef();
  const totalClients = current + importing;

  const [currentTextLeft, setCurrentTextLeft] = useState(null);

  useLayoutEffect(() => {
    if (
      currentTextRef?.current &&
      caretRef?.current &&
      progressBarRef?.current
    ) {
      const caretRect = caretRef.current.getBoundingClientRect();
      const currentTextRect = currentTextRef.current.getBoundingClientRect();
      const progressBarRect = progressBarRef.current.getBoundingClientRect();

      let newLeft = null;
      if (currentTextRect.right > progressBarRect.right) {
        // when text goes past the progress bar, pull it back
        newLeft = currentTextRect.right - progressBarRect.right;
      } else {
        // otherwise, center the text
        newLeft = currentTextRect.width / 2 - caretRect.width / 2;
      }

      if (newLeft) {
        setCurrentTextLeft(`${-(newLeft / 10)}rem`);
      }
    }
  }, [current]);

  const currentPercent =
    current >= max ? 100 : ((current - 0) / (max - 0)) * 100;
  const importingPercent =
    totalClients >= max ? 100 : ((totalClients - 0) / (max - 0)) * 100;
  const isFilling = importingPercent < 100 && importingPercent > 0;
  const isOverfilled = totalClients > max;

  return (
    <div {...scope} className="progress-bar" ref={progressBarRef}>
      <div
        className="caret-wrapper"
        style={{ left: `calc(${importingPercent}% - ${caretSize} - .051rem)` }}
      >
        {(!!isFilling || isOverfilled) && (
          <>
            <div
              className={a("current-text cps-body").m(
                "current-text--overfilled cps-wt-semibold",
                isOverfilled,
              )}
              style={{ left: currentTextLeft || "0" }}
              ref={currentTextRef}
            >
              {totalClients}
            </div>
            <div className="caret" data-testid="caret" ref={caretRef} />
          </>
        )}
      </div>

      <div className="background">
        <div
          className={a("importing-foreground").m(
            "foreground--filling",
            isFilling,
          )}
          style={{
            width: `${importingPercent}%`,
          }}
        />
        <div
          className={a("current-foreground").m(
            "foreground--filling",
            currentPercent < 100 && currentPercent > 0,
          )}
          style={{
            width: `${currentPercent}%`,
            marginTop: "-1.2rem",
          }}
        />
      </div>

      <div className="bottom-text cps-body">
        <div className="min-text">0</div>
        <div className="max-text">client limit: {max}</div>
      </div>
    </div>
  );
}

const barSize = "1.2rem";
const caretSize = ".4rem";
const transition = "200ms ease-out";

const css = k`
  .progress-bar {
    width: 100%;
    height: fit-content;
    height: -moz-fit-content;
  }

  .background {
    width: 100%;
    height: ${barSize};
    background-color: var(--cps-color-border);
    border-radius: 2.5rem;
  }

  .current-foreground {
    height: ${barSize};
    border-radius: 2.5rem;
    background-color: var(--cps-color-primary);
    transition: width ${transition};
  }

  .importing-foreground {
    height: ${barSize};
    border-radius: 2.5rem;
    background-color: #A5C8FD;
    background-image: repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 5px, 
      #fff 5px, 
      #fff 7px
    );
    transition: width ${transition};
  }

  .current-foreground.foreground--filling {
    border-radius: 2.5rem 0 0 2.5rem;
  }

  .importing-foreground.foreground--filling {
    border-radius: 2.5rem 0 0 2.5rem;
    border-right: .1rem solid var(--cps-color-primary-text);
  }

  .bottom-text {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .current-text {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .current-text--overfilled {
    color: var(--cp-color-app-error-text);
  }

  .caret-wrapper {
    position: relative;
    height: 2.35rem;
    transition: left ${transition};
    width: fit-content;
    width: -moz-fit-content;
  }

  .caret {
    width: 0;
    height: 0;
    border-top: ${caretSize} solid var(--cps-color-primary-text);
    border-left: ${caretSize} solid transparent;
    border-bottom: ${caretSize} solid transparent;
    border-right: ${caretSize} solid transparent;
  }
`;
