import React, { lazy, Suspense } from "react";
import Disposable from "react-disposable-decorator";
import { Scoped, k } from "kremling";
import { CpButton, cdnImage } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { DateTime } from "luxon";

import { getIrsOrganizations, getIrsAuthUrl } from "./irs-integration.resource";

const IrsStatus = lazy(() =>
  SystemJS.import("transcripts-ui!sofe").then((mod) => mod.getIrsStatus()),
);

@Disposable
export default class IRSIntegrationCard extends React.Component {
  state = {
    fetchingConnection: false,
    organizations: [],
    irsSignInLoading: false,
  };

  componentDidMount() {
    const { permissions } = this.props;

    if (permissions?.hasTranscripts) {
      this.getIrsInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.permissions.hasTranscripts &&
      this.props.permissions.hasTranscripts
    ) {
      this.getIrsInfo();
    }
  }

  getIrsInfo = () => {
    this.setState({ fetchingConnection: true });

    this.props.cancelWhenUnmounted(
      getIrsOrganizations().subscribe(
        (organizations) => {
          this.setState({
            fetchingConnection: false,
            organizations,
          });
        },
        (err) => {
          this.setState({ fetchingConnection: false });
          handleError(err);
        },
      ),
    );
  };

  routeToIrs = () => {
    this.props.cancelWhenUnmounted(
      getIrsAuthUrl().subscribe((authUrl) => {
        let screenWidth =
          window.screenWidth != undefined ? window.screenWidth : screen.width;

        const irsLoginWindow = window.open(
          authUrl,
          "Login to IRS",
          `top=${window.screenTop}, left=${screenWidth}, width=800, height=800`,
        );

        this.setState({ irsSignInLoading: true });

        // when the window closes check the current qbo connection status again
        const checkAuthWindow = () => {
          if (irsLoginWindow.closed) {
            this.setState({ irsSignInLoading: false });
          } else {
            setTimeout(checkAuthWindow, 2000);
          }
        };
        // don't check if the window is closed the first time for 5 seconds
        setTimeout(checkAuthWindow, 5000);
      }, handleError),
    );
  };

  getActiveOrgs = (organizations) =>
    organizations.filter(
      (org) => org.login_needed_at > DateTime.now().toUTC().toISO(),
    );

  render() {
    const { organizations, irsSignInLoading } = this.state;
    const { permissions } = this.props;

    let descriptionText = "Sign in to request transcripts from the IRS";

    if (!permissions?.hasTranscripts || !permissions?.hasTranscriptsPull) {
      descriptionText =
        "Account permission needed to pull transcripts from the IRS.";
    }

    return (
      <Scoped css={css}>
        <div className="irs-card">
          <div className="irs-logo-header">
            <div>
              <img src={cdnImage("irs_logo.jpg")} height="60" alt="IRS logo" />
            </div>
            <div className="irs-title-text">Internal Revenue Service</div>
          </div>
          <div className="irs-description-text">{descriptionText}</div>
          {this.getActiveOrgs(organizations).length ? (
            <div style={{ width: "24rem", height: "4rem" }}>
              <Suspense fallback={<></>}>
                <IrsStatus
                  organizations={organizations}
                  fetchOrganizations={this.getIrsInfo}
                />
              </Suspense>
            </div>
          ) : (
            <div className="irs-connect-button">
              <CpButton
                btnType="secondary"
                block
                onClick={this.routeToIrs}
                showLoader={irsSignInLoading}
                disabled={
                  !permissions?.hasTranscripts ||
                  !permissions?.hasTranscriptsPull
                }
              >
                Sign in
              </CpButton>
            </div>
          )}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .irs-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 31.4rem;
    width: 26.8rem;
    padding: 2.8rem 1.6rem 4.4rem;
    border: 0.1rem solid var(--cp-color-app-border);
    border-radius: 0.5rem;
  }

  .irs-logo-header {
    margin: auto;
    text-align: center;
  }

  .irs-title-text {
    margin: 1.4rem auto 0;
    width: 23.4rem;
    height: 3.7rem;
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
    font-weight: 600;
  }

  .irs-description-text {
    margin: auto;
    width: 23.4rem;  
    height: 8rem;
    font-size: 1.4rem !important;
    line-height: 2.0rem !important;
    text-align: center;
  }

  .irs-connect-button {
    display: block;
    width: 23.4rem;
  }
`;
