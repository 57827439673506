import React, { useState, useEffect } from "react";
import queryString from "query-string";

import { NavContent } from "primary-navbar!sofe";
import { k, useCss } from "kremling";
import {
  userHasOnlyLicense,
  useHasAccess,
  useWithUserAndTenant,
} from "cp-client-auth!sofe";

import IRSIntegrationCard from "./irs-integration/irs-integration-card.component";
import QboIntegrationCard from "./qbo-integration/qbo-integration-card.component";
import XeroIntegrationCard from "./xero-integration/xero-integration-card.component";
import { EmailIntegrationCards } from "./email-integration/email-integration-cards.component";
import SuccessPage from "./common/success-page.component";
import ContinueIntegrationPage from "./common/continue-integration-page.component";
import { IntegrationCard } from "./integration-card.component.tsx";
import { getIntegrationsOptions } from "./constants";
import { featureEnabled } from "feature-toggles!sofe";

export default function Integrations({ location }) {
  const [user, tenant] = useWithUserAndTenant();
  const permissions = {
    hasTranscripts: useHasAccess("transcripts"),
    hasTranscriptsPull: useHasAccess("transcripts_pull"),
    hasIntegrationsPermissions: useHasAccess("integrations_configure"),
    hasDesktopAssistant: useHasAccess("files_desktop_assistant"),
    hasVirtualDrive: useHasAccess("files_virtual_drive"),
    hasOdrive: useHasAccess("files_odrive"),
    hasCommunications: useHasAccess("communications"),
  };

  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [showContinuePage, setShowContinuePage] = useState(false);
  const [code, setCode] = useState("");
  const [realmId, setRealmId] = useState("");
  const [stateParam, setStateParam] = useState("");

  const scoped = useCss(css);

  useEffect(() => {
    const parsedQueryString = queryString.parse(location.search);
    const { integrating, code, state, realmId } = parsedQueryString;

    // if true, then this component has been mounted inside the popup window and needs to be closed
    if (
      integrating &&
      (integrating === "qbo" ||
        integrating === "quickbooksonline" ||
        integrating === "success")
    ) {
      setShowSuccessPage(true);
      setTimeout(window.close, 2000); // Closes the window after 2 seconds
    }

    if (integrating && integrating === "close") {
      window.close(); // Closes the window immediately
    }

    if (code && state && realmId) {
      setShowContinuePage(true);
      setCode(code);
      setRealmId(realmId);
      setStateParam(state);
    }
  }, [location.search]);

  const transcriptsOnly = userHasOnlyLicense(user, "transcripts");
  const showZapier = tenant?.crm_status === "crm_old";

  return (
    <NavContent
      hasTopnavSecondary={true}
      style={{
        marginLeft: "31.8rem",
        width: "calc(100% - 31.8rem)",
        padding: "2.4rem 1.6rem",
        minWidth: "64rem",
      }}
    >
      <div className="cps-card" {...scoped}>
        <div className="cps-subheader cp-pt-16 cp-pb-16 cp-pl-24 cp-pr-24">
          Integrations
        </div>
        <hr className="cp-m-0" />
        <div className="integrations">
          <IRSIntegrationCard permissions={permissions} />
          <QboIntegrationCard
            hasIntegrationsPermissions={permissions.hasIntegrationsPermissions}
          />
          {featureEnabled("corona_ft_xero") && (
            <XeroIntegrationCard
              permissions={permissions}
              location={location}
            />
          )}
          {getIntegrationsOptions(transcriptsOnly, showZapier).map((card) => (
            <IntegrationCard
              type={card}
              key={card.name}
              hasPermission={permissions[card.permission]}
            />
          ))}
          {permissions.hasCommunications && <EmailIntegrationCards />}
          {showSuccessPage && <SuccessPage />}
          {showContinuePage && (
            <ContinueIntegrationPage
              code={code}
              realmId={realmId}
              state={stateParam}
            />
          )}
        </div>
      </div>
    </NavContent>
  );
}

const css = k`
  .integrations {
    display: flex;
    position: relative;
    padding: 1.6rem;
    gap: 32px 20px;
    flex-wrap: wrap;
  }
`;
