import React from "react";
import { useCss } from "kremling";
import { CpWell } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";

import { commonCss } from "../sync-preview.styles";
import { getServiceItemsHeaderRow } from "../sync-preview.helpers";
import { QboServiceItemRow } from "./service-item-row";

export default function NewServiceItemSection({ newServiceItems, readOnly }) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell className="section-instructions">
        <div className="instructions-description">
          <div className="cps-title cps-wt-semibold">New Service Items</div>
          <div className="cp-pv-8">
            {readOnly
              ? "This is the list of services in QBO that were found and created as new service items in Canopy."
              : "This is the list of services in QBO that were found and will be created as new service items in Canopy."}
          </div>
        </div>
      </CpWell>

      {!isEmpty(newServiceItems) && (
        <div className="clients-display">
          <table className="client-table">
            {getServiceItemsHeaderRow()}
            <tbody>
              {newServiceItems.map((serviceItem) => (
                <QboServiceItemRow
                  key={`qbo-new-si-${serviceItem.third_party_item_id}`}
                  qboSI={serviceItem}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
