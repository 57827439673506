import React from "react";
import { useCss } from "kremling";
import { CpWell } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";

import { commonCss } from "../sync-preview.styles";
import { getClientHeaderRow } from "../sync-preview.helpers";
import { QboClientRow } from "./client-row.component";

export default function ClientUnmatchedThirdPartySection({
  unmatchedThirdPartyClients,
  newCrmEnabled
}) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell
        className="section-instructions cp-flex-spread-center"
        style={{ width: "100%", maxWidth: "110rem" }}
      >
        <div style={{ width: "68rem" }}>
          <div className="cps-title cps-wt-semibold">Unmatched QBO Clients</div>
          <div className="cp-pv-8">
            This is the number of QuickBooks Online clients that were NOT
            created in Canopy. The admin that set up the sync opted out of
            creating them. To create these clients in Canopy you may restart the
            client sync.
          </div>
        </div>
      </CpWell>

      {!isEmpty(unmatchedThirdPartyClients) && (
        <div className="clients-display">
          <table className="client-table">
            {getClientHeaderRow(newCrmEnabled)}
            <tbody>
              {unmatchedThirdPartyClients.map((client, idx) => (
                <QboClientRow
                  key={`qbo-new-client-${client.third_party_client_id}-${idx}`}
                  qboClient={client}
                  newCrmEnabled={newCrmEnabled}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
