import React from "react";
import { useCss } from "kremling";
import { CpWell } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";

import { commonCss } from "../sync-preview.styles";
import { getServiceItemsHeaderRow } from "../sync-preview.helpers";
import { QboServiceItemRow, CanopyServiceItemRow } from "./service-item-row";

export default function ServiceItemMatchesSection({ matches }) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell className="section-instructions">
        <div className="instructions-description">
          <div className="cps-title cps-wt-semibold">Matches</div>
          <div className="cp-pv-8">
            These Canopy service items have successfully matched with a QBO
            services.
          </div>
        </div>
      </CpWell>
      <div className="clients-display">
        {!isEmpty(matches) &&
          matches.map((serviceItems) => {
            const canopySI = serviceItems.canopy;
            const thirdParty = serviceItems.third_party;
            const thirdPartyId = thirdParty.third_party_item_id;

            return (
              <table
                key={`matches-${thirdPartyId || canopySI?.id}`}
                className="client-table"
              >
                {getServiceItemsHeaderRow()}
                <tbody>
                  <CanopyServiceItemRow
                    key={`canopy-si-match-${canopySI?.id}`}
                    canopySI={canopySI}
                  />
                  <QboServiceItemRow
                    key={`qbo-si-${thirdPartyId}`}
                    qboSI={thirdParty}
                  />
                </tbody>
              </table>
            );
          })}
      </div>
    </div>
  );
}
