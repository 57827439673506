import React from "react";
import { useCss, k } from "kremling";
import { CpButton, cdnImage } from "canopy-styleguide!sofe";

export default function QboIntegrationCard({ hasIntegrationsPermissions }) {
  const scoped = useCss(css);
  let descriptionText =
    "Sync your clients, service items, invoices, payments, and credits.";

  let actionButton = hasIntegrationsPermissions ? (
    <CpButton
      btnType="secondary"
      block
      anchor
      href="/#/global-settings/billing/integrations"
    >
      Manage
    </CpButton>
  ) : (
    <CpButton btnType="secondary" block disabled>
      Manage
    </CpButton>
  );

  if (!hasIntegrationsPermissions) {
    descriptionText = "Account permission needed to access this integration";
  }

  return (
    <div {...scoped} className="qbo-card">
      <div className="qbo-logo-header">
        <img
          src={cdnImage("qbo_logo_light_horizontal.png")}
          height="55"
          alt="QuickBooks Online Logo"
        />
      </div>

      <div className="qbo-title-text">QuickBooks Online</div>
      <div className="qbo-description-text">{descriptionText}</div>
      <div className="qbo-connect-button">{actionButton}</div>
    </div>
  );
}

const css = k`
  .qbo-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 31.4rem;
    width: 26.8rem;
    border: 0.1rem solid var(--cp-color-app-border);
    border-radius: 0.5rem;
    position: relative;
  }

  .qbo-logo-header {
    display: flex;
    width: 23.4rem;
    justify-content: space-evenly;
    margin-top: 3.2rem;
  }

  .qbo-title-text {
    margin: 2.4rem auto 0;
    width: 22.6rem;
    height: 4rem;
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
    font-weight: 600;
    text-align: center;
  }

  .qbo-description-text {
    width: 22.6rem;
    margin-bottom: 4.4rem;
    font-size: 1.4rem !important;
    text-align: center;
  }

  .qbo-connect-button {
    display: block;
    width: 23.4rem;
  }
`;
