import React from "react";
import { CpCard, CpHeader } from "canopy-styleguide!sofe";
import { EmailIntegrationConnect } from "./email-integration-connect.component";
import { useCss, k } from "kremling";
import { providers } from "./email-integration.helpers";

export const EmailIntegrationCard = (props) => {
  const scope = useCss(css);
  const provider = providers[props.provider];

  return (
    <CpCard level={1} {...scope} className="integration-card-container">
      <div className="integration-card-logo">
        <img src={provider.icon} height={60} />
      </div>
      <CpHeader level={1} className="integration-card-title" border={false}>
        {provider.header}
      </CpHeader>
      <div className="integration-card-description cp-body">
        <div className="info-description">{provider.description}</div>
      </div>
      <div className="info-description"></div>
      <EmailIntegrationConnect />
    </CpCard>
  );
};

const css = k`
  .integration-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 31.4rem;
    width: 26.8rem;
    padding: 2.8rem 1.6rem 4.4rem;
  }

  .integration-card-logo {
    max-height: 6rem !important;
    max-width: 20rem !important;
    margin: auto;
  }

  .integration-card-title {
    justify-content: center;
    padding: 0;
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
    font-weight: 600;
  }

  .integration-card-description {
    width: 23.4rem;
    height: 8rem;
    text-align: center;
    margin: auto;
  }
`;
