import React from "react";
import { CpButton, CpNameChip } from "canopy-styleguide!sofe";
import { QboIcon } from "../common/qbo-icon.component";

type Props = {
  onCreateNew: () => void;
  onSearchExisting: () => void;
  creatingNew: boolean;
  defaultSearchExisting?: boolean;
};

export function RowNoMatch(props: Props) {
  const { creatingNew, onCreateNew, onSearchExisting, defaultSearchExisting } = props;

  return (
    <tr className="box">
      <td>
        <div className="flex items-center cp-gap-16">
          <CpNameChip name="-" /> No QBO Match
        </div>
      </td>
      <td className="cp-text-center">
        <QboIcon />
      </td>
      <td colSpan={5}>
        <div className="flex items-center justify-between cp-gap-16 cp-mr-8">
          <div>
            <strong>Select a QBO match:</strong> You may create the above client
            as “new” in QBO or you may search for an existing QBO client to
            match with.
          </div>
          <div className="flex items-center cp-gap-12 cp-pr-8">
            <CpButton
              btnType="secondary"
              icon="person-people"
              disabled={creatingNew}
              onClick={onSearchExisting}
            >
              Search existing
            </CpButton>
            {!defaultSearchExisting && <CpButton
              btnType="secondary"
              icon="person-add"
              onClick={onCreateNew}
              showLoader={creatingNew}
            >
              Create new
            </CpButton>}
          </div>
        </div>
      </td>
    </tr>
  );
}
