import React from "react";
import { useCss } from "kremling";
import { CpWell } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";

import { commonCss } from "../sync-preview.styles";
import { getClientHeaderRow } from "../sync-preview.helpers";
import { CanopyClientRow, QboClientRow } from "./client-row.component";

export default function ClientMatchesSection({ matches, newCrmEnabled }) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell className="section-instructions">
        <div className="instructions-description">
          <div className="cps-title cps-wt-semibold">Matched Clients</div>
          <div className="cp-pv-8" style={{ maxWidth: "80rem" }}>
            The following QBO and Canopy clients have a matching display name
            and will be synced together. Upon syncing, the client information in
            Canopy, using the assigned primary contact info, will override the
            client information in QBO.
          </div>
        </div>
      </CpWell>
      <div className="clients-display">
        {!isEmpty(matches) &&
          matches.map((clients, idx) => {
            const canopyClient = clients.canopy;
            const qboClient = clients.third_party || clients.qbo;
            const clientId = qboClient.third_party_client_id;

            return (
              <table
                key={`matches-${clientId || canopyClient?.id}-${idx}`}
                className="client-table"
              >
                {getClientHeaderRow(newCrmEnabled)}
                <tbody>
                  <CanopyClientRow
                    key={`canopy-matched-client-${canopyClient?.id}-${idx}`}
                    canopyClient={canopyClient}
                    newCrmEnabled={newCrmEnabled}
                  />
                  <QboClientRow
                    key={`qbo-matched-${clientId}-${idx}`}
                    qboClient={qboClient}
                    newCrmEnabled={newCrmEnabled}
                  />
                </tbody>
              </table>
            );
          })}
      </div>
    </div>
  );
}
