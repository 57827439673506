import React, { useEffect, useState, useRef } from "react";
import { k, useCss } from "kremling";
import {
  CpButton,
  CpOverlay,
  cdnImage,
  constants,
  CpIcon,
} from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

import {
  startPaymentsSync,
  updatePaymentsSyncStatus,
  getIntegrations,
} from "../../integrations.resource";
import Instructions from "./instructions.component";
import { AdjustQboSettingsBody } from "./adjust-qbo-setting.component";

import type { IntegrationInfo } from "../../types/integration.d.ts";

export default function PaymentsPreview({
  qboIntegrationInfo,
  setShowSyncPreview,
  hasPaymentsPermissions,
  syncStartSection,
}: {
  qboIntegrationInfo: IntegrationInfo;
  setShowSyncPreview: ({
    showSyncPreview,
  }: {
    showSyncPreview: boolean;
  }) => void;
  hasPaymentsPermissions: boolean;
  syncStartSection: string;
}) {
  const [user] = useWithUserAndTenant();
  const syncPaymentsSub = useRef<any>();
  const getIntegrationsSub = useRef<any>();
  const scoped = useCss(css);
  const [loading, setLoading] = useState(false);
  const [showAutoApplyInstructions, setShowAutoApplyInstructions] =
    useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    return () => {
      getIntegrationsSub.current?.unsubscribe();
      syncPaymentsSub.current?.unsubscribe();
    };
  }, []);

  const checkIntegrations = () => {
    setLoading(true);
    getIntegrationsSub.current = getIntegrations().subscribe(
      (integrations: Array<IntegrationInfo>) => {
        const qboConnection = integrations.find(
          (integration: IntegrationInfo) => integration.type === "qbo",
        );

        if (
          qboConnection &&
          !qboConnection.preferences?.SalesFormsPrefs?.AutoApplyCredit
        ) {
          setShowWarning(false);
          syncPayments({ qboConnection });
        } else {
          setShowWarning(true);
          setLoading(false);
        }
      },
      (err: Error) => {
        setLoading(false);
        handleError(err);
      },
    );
  };

  const syncPayments = ({
    qboConnection,
  }: {
    qboConnection?: IntegrationInfo;
  }) => {
    if (
      qboConnection?.preferences?.SalesFormsPrefs?.AutoApplyCredit ||
      (!qboConnection &&
        qboIntegrationInfo?.preferences?.SalesFormsPrefs?.AutoApplyCredit)
    ) {
      return setShowAutoApplyInstructions(true);
    }

    setLoading(true);
    const startSync = qboIntegrationInfo.payments?.sync_status?.first_synced_at
      ? updatePaymentsSyncStatus
      : startPaymentsSync;
    syncPaymentsSub.current = startSync(
      qboIntegrationInfo.id,
      qboIntegrationInfo.type,
      true,
      user,
    ).subscribe(
      () => {
        setLoading(false);
        setShowAutoApplyInstructions(false);
        setShowConfirmation(true);
      },
      (err: Error) => {
        setLoading(false);
        handleError(err);
      },
    );
  };

  return (
    <>
      <CpOverlay.Header title="QBO Payment and Credit Sync">
        <div className="cp-flex-center">
          {showWarning && (
            <div className="cp-mr-8 cp-color-app-warning-text">
              <CpIcon
                name="alert-triangle-filled-small"
                fill="var(--cp-color-app-warning-text)"
              />
              The setting must be OFF to resume your sync
            </div>
          )}
          {!showConfirmation && (
            <>
              {hasPaymentsPermissions && syncStartSection !== "payments" && (
                <CpButton
                  btnType="secondary"
                  onClick={() => setShowSyncPreview({ showSyncPreview: false })}
                  disabled={loading}
                >
                  Cancel
                </CpButton>
              )}
              <CpButton
                btnType="primary"
                onClick={() => {
                  if (showAutoApplyInstructions) {
                    checkIntegrations();
                  } else {
                    syncPayments({});
                  }
                }}
                showLoader={loading}
                className="cp-ml-8"
                disabled={!hasPaymentsPermissions}
              >
                {showAutoApplyInstructions ? "Retry sync" : "Sync"}
              </CpButton>
            </>
          )}
          {showConfirmation && (
            <CpButton
              onClick={() => setShowSyncPreview({ showSyncPreview: false })}
            >
              Done
            </CpButton>
          )}
        </div>
      </CpOverlay.Header>
      <CpOverlay.Body>
        {hasPaymentsPermissions ? (
          <>
            {showAutoApplyInstructions && <AdjustQboSettingsBody />}
            {!showAutoApplyInstructions && !showConfirmation && (
              <div {...scoped} className="instructions">
                <div className="instructions-left">
                  <img
                    src={cdnImage("canopy_qbo_payment_sync.svg")}
                    height={130}
                    alt="Payments Illustration"
                  />
                </div>
                <div className="instructions-right">
                  <div className="instructions-header cps-wt-semibold">
                    Begin Payment & Credit Sync
                  </div>
                  <div className="instruction cp-pt-8 cp-pb-16">
                    Going forward, any new payment or credit created in Canopy
                    will have the ability to sync in QBO as a one-way sync.
                  </div>
                  <div className="instruction-subheader">What to expect?</div>
                  <div className="instruction cp-mb-16">
                    Credits created in Canopy will sync to QBO as a credit memo
                  </div>
                  <div className="instruction">
                    The undeposited funds account in QBO will receive the
                    following payment types from Canopy:
                  </div>
                  <ul>
                    <li>Payments processed through Canopy</li>
                    <li>Manual payments created in Canopy</li>
                    <li>Additional payments</li>
                  </ul>
                  <div className="instruction-subtext">
                    Received as a credit in the undeposited funds account
                  </div>
                </div>
              </div>
            )}
            {!showAutoApplyInstructions && showConfirmation && (
              <div {...scoped} className="instructions">
                <div className="instructions-left">
                  <img
                    src={cdnImage("payment_success.svg")}
                    height={380}
                    alt="Success Illustration"
                    style={{ marginTop: "-2.4rem" }}
                  />
                </div>
                <div className="instructions-right">
                  <div className="instructions-header cp-wt-semibold">
                    Payments & credits are successfully synced
                  </div>
                  <div className="instruction cp-pt-8 cp-pb-16">
                    Now, when new payments/credits are created in Canopy they
                    will be automatically reflected in QuickBooks Online.
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Instructions
            imgSrc="need_permissions_graphic.svg"
            instructionsTitle="You don't currently have permissions to sync payments:"
            instructions={[
              "To gain permission, please contact your account manager to change your permissions.",
              "After your permissions have been changed, restart your sync process to try again.",
              `If you believe there is an issue, please contact Canopy support: ${constants.canopyPhone}.`,
            ]}
            isNumberedList
            integration={qboIntegrationInfo}
          />
        )}
      </CpOverlay.Body>
    </>
  );
}

const css = k`
  .instructions {
    display: flex;
    justify-content: center;
    margin: 14rem 2rem 0;
  }

  .instructions-right {
    margin-left: 5rem;
    width: 47rem;
  }

  .instructions-left {
    display: flex;
    align-items: center;
    min-width: 42rem;
  }

  .instructions-header {
    font-size: 3.2rem;
    padding: 0.8rem 0 0.8rem 0;
    max-width: 45rem;
    font-weight: 600;
  }

  .instruction {
    max-width: 55rem;
    font-size: 1.6rem;
    color: var(--cp-color-app-secondary-text);
  }

  .instructions li {
    font-size: 1.6rem;
    color: var(--cp-color-app-secondary-text);
    padding-top: 2.4rem;
  }

  .instruction-subheader {
    font-size: 1.6rem;
    color: var(--cp-color-app-primary-text);
    font-weight: 600;
  }

  .instruction-subtext {
    font-size: 1.4rem;
    font-style: italic;
    font-weight: 400;
    margin-left: 3.8rem;
    color: var(--cp-color-app-inactive-text);
  }
`;
