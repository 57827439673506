import React from "react";
import { useCss } from "kremling";
import { CpWell, CpToggle, CpTooltip, CpIcon } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";

import { commonCss } from "../sync-preview.styles";
import { getClientHeaderRow } from "../sync-preview.helpers";
import { QboClientRow } from "./client-row.component";

export default function NewClientsSection({
  newClients,
  newClientsAmount,
  readOnly,
  setSyncUnmatchedThirdPartyClients,
  syncUnmatchedThirdPartyClients,
  newCrmEnabled
}) {
  const scope = useCss(commonCss);

  return (
    <div {...scope}>
      <CpWell
        className="section-instructions cp-flex-spread-center"
        style={{ width: "100%", maxWidth: "110rem" }}
      >
        <div style={{ width: "70rem" }}>
          <div className="cps-title cps-wt-semibold">New Clients</div>
          <div className="cp-pv-8">
            {readOnly
              ? "This is the number of QuickBooks Online clients that were completely new and added to Canopy."
              : `Unless toggled OFF, these QBO customers will be created as new Canopy clients with a new primary contact.`}
          </div>
          {!readOnly && (
            <div className="cp-flex-center cp-mr-16">
              <CpToggle
                onChange={setSyncUnmatchedThirdPartyClients}
                checked={syncUnmatchedThirdPartyClients}
                className="cp-mr-8"
              />
              <strong className="cp-mr-8">
                Create {newClientsAmount} new client
                {newClientsAmount > 1 ? "s" : ""} in Canopy
              </strong>
              <CpTooltip position="right-start" text="Client records will be created in Canopy using the unique QBO display name. For each client record created, 1 new primary contact will also be created (individuals only) and added to the client record. However, a contact will not be created for business clients.">
                <CpIcon name="information-circle-open-small" />
              </CpTooltip>
            </div>
          )}
        </div>
      </CpWell>

      {!isEmpty(newClients) && (
        <div className="clients-display">
          <table className="client-table">
            {getClientHeaderRow(newCrmEnabled)}
            <tbody>
              {newClients.map((client, idx) => (
                <QboClientRow
                  key={`qbo-new-client-${client.third_party_client_id}-${idx}`}
                  qboClient={client}
                  newCrmEnabled={newCrmEnabled}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
