import React from "react";
import { isEmpty, cloneDeep } from "lodash";
import { CpPagination, constants } from "canopy-styleguide!sofe";

export function getQboAddress(addressList = []) {
  const physical = addressList.find((address) => address.key === "physical");
  const mailing = addressList.find((address) => address.key === "mailing");

  // default to physical then mailing, if there is neither then just return null
  if (physical?.value?.address_1) {
    return physical.value;
  } else if (mailing?.value?.address_1) {
    return mailing.value;
  }

  return null;
}

export const formatNestedQboData = (qboData) => {
  const clonedQboData = cloneDeep(qboData);
  const formattedList = [];
  // sort the list from top level to bottom level
  clonedQboData.sort((a, b) => {
    // FullyQualifiedName is a colon separated string that shows hierarchy ex: Parent:child1:child2
    // QBO doesn't sort based on case so we convert everything to lower case before running the sort
    const aList = a.FullyQualifiedName.split(":").map((name) =>
      name.toLowerCase(),
    );
    const bList = b.FullyQualifiedName.split(":").map((name) =>
      name.toLowerCase(),
    );

    // order list from parent items at top to most nested items at bottom
    // when two items are at the top level, sort them alphabetically
    if (
      aList.length < bList.length ||
      (aList.length === 1 && bList.length === 1 && aList[0] < bList[0])
    ) {
      return -1;
    } else if (
      aList.length > bList.length ||
      (aList.length === 1 && bList.length === 1 && aList[0] > bList[0])
    ) {
      return 1;
    } else {
      // if two items are on the same nested level then sort them in reverse alphabetical order
      // since the forEach below will place them in the reverse order this list produces
      if (aList[aList.length - 1] < bList[bList.length - 1]) {
        return 1;
      } else if (aList[aList.length - 1] > bList[bList.length - 1]) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  clonedQboData.forEach((item) => {
    const splitName = item.FullyQualifiedName.split(":");
    const formattedObj = {
      ...item,
      id: item.Id,
      name: item.Name,
      indent: `${splitName.length - 1}`,
    };
    if (!item.SubClass && !item.SubDepartment && !item.SubItem) {
      return formattedList.push(formattedObj);
    }
    const parentIndex = formattedList.findIndex(
      (parent) => parent.Id === item.ParentRef.value,
    );
    // insert item below its parent class in the list
    formattedList.splice(parentIndex + 1, 0, formattedObj);
  });

  return formattedList;
};

export function getClientName(client, newCrmEnabled) {
  if (newCrmEnabled) {
    return client?.name
  } else if (client?.is_business) {
    return client.business_name;
  } else {
    return `${client?.first_name}${
      client?.middle_name ? ` ${client?.middle_name} ` : " "
    }${client?.last_name}`;
  }
}

export function getClientHeaderRow(newCrmEnabled) {
  return (
    <thead>
      <tr>
        <th className="sticky-cell">
          <div className="client-name-cell">{newCrmEnabled ? "Client display name" : "Client name"}</div>
        </th>
        <th className="small-col">{newCrmEnabled ? "Contact" : "Display name"}</th>
        <th className="small-col">Phone</th>
        <th>Email</th>
        <th>Address</th>
        <th className="small-col">City</th>
        <th className="small-col">State</th>
        <th className="small-col">Zip</th>
        <th className="small-col">Country</th>
        <th className="small-col">Active</th>
      </tr>
    </thead>
  );
}

export function getServiceItemsHeaderRow() {
  return (
    <thead>
      <tr>
        <th
          className="sticky-cell"
          style={{
            borderLeft: "0.1rem solid var(--cp-color-card-border)",
            borderRight: "0.1rem solid var(--cp-color-card-border)",
          }}
        >
          <div
            className="client-name-cell"
            style={{
              borderLeft: 0,
              borderRight: 0,
            }}
          >
            Service Item
          </div>
        </th>
        <th className="large-col">Description</th>
        <th className="small-col">Rate</th>
        <th className="small-col">Rate Type</th>
        <th className="small-col">Tax rate</th>
        <th className="small-col">SKU/Code</th>
      </tr>
    </thead>
  );
}

export function findSectionsWithData(syncPreviewData) {
  let activeSection;
  let sectionsVisited = [];

  if (!isEmpty(syncPreviewData?.duplicates)) {
    activeSection = sections.DUPLICATES;
    sectionsVisited.push(sections.DUPLICATES);
  } else if (!isEmpty(syncPreviewData?.unmatchedCanopy)) {
    activeSection = sections.UNMATCHED_CANOPY;
    sectionsVisited.push(sections.UNMATCHED_CANOPY);
  } else if (!isEmpty(syncPreviewData?.unmatchedThirdParty)) {
    activeSection = sections.UNMATCHED_THIRD_PARTY;
    sectionsVisited.push(sections.UNMATCHED_THIRD_PARTY);
  } else if (!isEmpty(syncPreviewData?.matches)) {
    activeSection = sections.MATCHES;
    sectionsVisited.push(sections.MATCHES);
  } else if (!isEmpty(syncPreviewData?.newClients)) {
    activeSection = sections.NEW_CLIENTS;
    sectionsVisited.push(sections.NEW_CLIENTS);
  } else if (!isEmpty(syncPreviewData?.newServiceItems)) {
    activeSection = sections.NEW_SERVICE_ITEMS;
    sectionsVisited.push(sections.NEW_SERVICE_ITEMS);
  }

  const objKeys = Object.keys(syncPreviewData);
  const sectionsWithData = [];
  objKeys.forEach((key) => {
    if (!isEmpty(syncPreviewData[key])) {
      sectionsWithData.push(key);
    }
  });

  return { activeSection, sectionsVisited, sectionsWithData };
}

export function formatPhoneNumber(phoneNumber, USonly = false) {
  phoneNumber = phoneNumber ? phoneNumber.toString() : "";
  let extension = "";

  // Check for an explicit extension
  let extParts = phoneNumber.split("x");
  if (extParts.length === 2) {
    phoneNumber = extParts[0].trim();
    extension = extParts[1].trim();
  }

  // Remove all non-digits and whitespace
  let strippedNumber = phoneNumber.replace(/[\D\s]/g, "");

  if (strippedNumber.charAt(0) === "1") {
    strippedNumber = strippedNumber.substr(1);
  }

  if (USonly && strippedNumber.length > 10) {
    return (
      "(" +
      strippedNumber.substr(0, 3) +
      ") " +
      strippedNumber.substr(3, 3) +
      "-" +
      strippedNumber.substr(6, 4) +
      " x " +
      strippedNumber.substr(10)
    );
  } else if (strippedNumber.length === 10) {
    return (
      "(" +
      strippedNumber.substr(0, 3) +
      ") " +
      strippedNumber.substr(3, 3) +
      "-" +
      strippedNumber.substr(6, 4) +
      (extension.length > 0 ? " x " + extension : "")
    );
  }
  return phoneNumber + (extension.length > 0 ? " x " + extension : "");
}

export const getInstructionPageObjects = (activeSection, ftCrm) => {
  return {
    clientsConfirmationObject: {
      imgSrc: "client_sync_success.svg",
      instructionsHeader: "Clients are successfully synced",
      instructions: [
        <div key="client-success">
          Now, when you create a client or update an existing client in Canopy
          those actions will be able to sync to QuickBooks Online{" "}
          <span className="cp-color-app-warning-text">and vice versa.</span>
        </div>,
        "Next step is helping you sync service items and invoices with QuickBooks Online.",
      ],
      isNumberedList: false,
    },
    clientsOnlyConfirmationObject: {
      imgSrc: "qbo_client_sync_successful.svg",
      instructionsTitle:
        "Your clients have been successfully synced with QuicksBooks Online",
      instructions: [
        "Now, when you create a client or update an existing client in Canopy those actions will be reflected in QuickBooks Online automatically.",
      ],
      isNumberedList: false,
    },
    clientsInstructionsObject: {
      imgSrc: "client_sync.svg",
      instructionsHeader: "Begin client sync",
      instructionsSubHeader:
        "This sync will allow all clients created or edited in Canopy to have the ability to sync to QBO and vice versa.",
      instructionsTitle: "Steps for success:",
      instructions: [
        "Check for duplicates in QBO and Canopy.",
        "If you have existing clients in Canopy, ensure the names of each client match the display name in QBO.",
        ...(ftCrm
          ? [
              "Understand that for every new individual client created in Canopy, one new primary contact will also be created and added to the client record.",
            ]
          : [
              "Understand that all active customers in QBO that don't match a client in Canopy will be created as a new client in Canopy.",
            ]),
      ],
      isUnorderedList: true,
    },
    permissionsInstructionsObject: {
      imgSrc: "need_permissions_graphic.svg",
      instructionsTitle:
        activeSection === sections.CLIENTS_PERMISSION_INSTRUCTIONS
          ? "You don't currently have permissions to sync clients:"
          : "You don't currently have permissions to sync service items and invoices:",
      instructions: [
        "To gain permission, please contact your account manager to change your permissions.",
        "After your permissions have been changed, restart your sync process to try again.",
        `If you believe there is an issue, please contact Canopy support: ${constants.canopyPhone}.`,
      ],
      isNumberedList: true,
    },
    servicesConfirmationObject: {
      imgSrc: "qbo_item_sync_successful.svg",
      instructionsTitle:
        "Your service items have been successfully synced with QuickBooks Online",
      instructions: [
        "Now, when you create new service items or invoices in Canopy those will be reflected in QuickBooks Online automatically. Income accounts tied to service items need to be managed in QuickBooks Online.",
      ],
      isNumberedList: false,
    },
    servicesInstructionsObject: {
      imgSrc: "qbo_begin_item_sync.svg",
      showLogo: true,
      instructionsTitle:
        "Recommended steps to successfully sync service items:",
      instructions: [
        "If you have existing service items in Canopy, ensure the names, rate, and sku/code of each service item match the services in QBO.",
        "All existing services in QBO will be created in Canopy if a matching service item is not found.",
        "Verify that the sales tax rate is correct.",
      ],
      isNumberedList: true,
      infoText:
        "New invoices created in Canopy will automatically be synced to QuickBooks Online.",
      infoIcon: "billing-invoice",
    },
    lateFeesObject: {
      imgSrc: "invoice_illustration.svg",
      instructionsTitle:
        "Set up your Canopy automated late fees to sync with QuickBooks Online",
      instructions: [
        "Automated late fees can be automatically applied to past due invoices. When late fees are added, we will send an updated invoice to your client.",
        "You already set this up in Canopy, we just need some additional information for your QBO sync.",
      ],
      isNumberedList: false,
      editIntegrationLateFees: true,
    },
  };
};

export const getActiveSection = (activeSection) => {
  if (activeSection === sections.SERVICES_INSTRUCTIONS) {
    return { showServiceItemsInstructions: true };
  } else if (activeSection === sections.CLIENTS_INSTRUCTIONS) {
    return { showClientsInstructions: true };
  } else if (activeSection === sections.CLIENTS_CONFIRMATION) {
    return { showClientsConfirmation: true };
  } else if (activeSection === sections.SERVICES_CONFIRMATION) {
    return { showServicesConfirmation: true };
  } else if (activeSection === sections.DUPLICATES) {
    return { duplicatesInView: true };
  } else if (activeSection === sections.UNMATCHED_CANOPY) {
    return { unmatchedCanopyInView: true };
  } else if (activeSection === sections.UNMATCHED_THIRD_PARTY) {
    return { unmatchedThirdPartyInView: true };
  } else if (activeSection === sections.MATCHES) {
    return { matchesInView: true };
  } else if (activeSection === sections.NEW_CLIENTS) {
    return { newClientsInView: true };
  } else if (activeSection === sections.NEW_SERVICE_ITEMS) {
    return { newServiceItemsInView: true };
  } else if (
    activeSection === sections.INVOICE_PERMISSION_INSTRUCTIONS ||
    activeSection === sections.CLIENTS_PERMISSION_INSTRUCTIONS
  ) {
    return { showPermissionInstructions: true };
  } else if (activeSection === sections.LATE_FEES_SETUP) {
    return { showLateFeesSetup: true };
  } else {
    return {};
  }
};

export const getInactiveQboClientCount = (newClients = []) => {
  return newClients?.filter((client) => !client.is_active).length || 0;
};

export const getAllSectionsVisited = (sectionsWithData, sectionsVisited) => {
  if (
    sectionsWithData.length > 0 &&
    sectionsWithData.length === sectionsVisited.length
  ) {
    return true;
  }
  return false;
};

export const getLastSection = (previewData) => {
  if (!isEmpty(previewData.newClients)) {
    return sections.NEW_CLIENTS;
  } else if (!isEmpty(previewData.newServiceItems)) {
    return sections.NEW_SERVICE_ITEMS;
  } else if (!isEmpty(previewData.matches)) {
    return sections.MATCHES;
  } else if (!isEmpty(previewData.unmatchedCanopy)) {
    return sections.UNMATCHED_CANOPY;
  } else if (!isEmpty(previewData.unmatchedThirdParty)) {
    return sections.UNMATCHED_THIRD_PARTY;
  } else if (!isEmpty(previewData.duplicates)) {
    return sections.DUPLICATES;
  }
};

export const sections = {
  CLIENTS_INSTRUCTIONS: "clientsInstructions",
  SERVICES_INSTRUCTIONS: "servicesInstructions",
  CLIENTS_CONFIRMATION: "clientsConfirmation",
  SERVICES_CONFIRMATION: "servicesConfirmation",
  DUPLICATES: "duplicates",
  UNMATCHED_CANOPY: "unmatchedCanopy",
  UNMATCHED_THIRD_PARTY: "unmatchedThirdParty",
  MATCHES: "matches",
  NEW_CLIENTS: "newClients",
  NEW_SERVICE_ITEMS: "newServiceItems",
  INVOICE_PERMISSION_INSTRUCTIONS: "invoicesPermissionInstructions",
  LATE_FEES_SETUP: "lateFeesSetup",
  CLIENTS_PERMISSION_INSTRUCTIONS: "clientsPermissionInstructions",
  PAYMENTS_CONFIRMATION: "paymentsConfirmation",
  PAYMENTS_ACCOUNTS: "paymentsAccounts",
};

export const getPaginator = ({
  previewData,
  sectionsInView,
  setPaginatedData,
}) => {
  const {
    duplicates,
    matches,
    newClients,
    newServiceItems = [],
    unmatchedCanopy,
    unmatchedThirdParty,
  } = previewData;
  const {
    duplicatesInView,
    matchesInView,
    newClientsInView,
    newServiceItemsInView = false,
    unmatchedCanopyInView,
    unmatchedThirdPartyInView,
  } = sectionsInView;
  let data = [];
  let key = "";

  if (duplicatesInView) {
    data = duplicates;
    key = sections.DUPLICATES;
  } else if (unmatchedCanopyInView) {
    data = unmatchedCanopy;
    key = sections.UNMATCHED_CANOPY;
  } else if (matchesInView) {
    data = matches;
    key = sections.MATCHES;
  } else if (newClientsInView) {
    data = newClients;
    key = sections.NEW_CLIENTS;
  } else if (newServiceItemsInView) {
    data = newServiceItems;
    key = sections.NEW_SERVICE_ITEMS;
  } else if (unmatchedThirdPartyInView) {
    data = unmatchedThirdParty;
    key = sections.UNMATCHED_THIRD_PARTY;
  }

  if (isEmpty(data) || !key) return;

  return (
    <CpPagination
      key={key}
      onChange={(paginatedData) => {
        if (!isEmpty(paginatedData)) {
          setPaginatedData(paginatedData);
        }
      }}
      data={data}
      perPage={150}
      pagesToDisplay={9}
    />
  );
};
