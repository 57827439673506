import React, { useState, useEffect } from "react";
import { useCss, k } from "kremling";
import { CpIcon, CpSelectSingle, cdnImage } from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";

import { formatNestedQboData } from "./sync-preview.helpers";
import { getIncomeAccounts, getCategories } from "../../integrations.resource";

export default function Instructions({
  imgSrc,
  instructionsTitle,
  instructionsHeader,
  instructionsSubHeader,
  instructions,
  infoText,
  infoIcon,
  integration,
  isNumberedList,
  isUnorderedList,
  lateFeeData,
  editIntegrationLateFees,
  showLogo,
  updateLateFeeData,
}) {
  const scope = useCss(css);
  const [incomeAccounts, setIncomeAccounts] = useState([]);
  const [incomeSearchValue, setIncomeSearchValue] = useState("");
  const [selectedIncomeAccount, setSelectedIncomeAccount] = useState();
  const [qboCategories, setQboCategories] = useState([]);
  const [categoriesSearchValue, setCategoriesSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    if (editIntegrationLateFees) {
      const subscription = getIncomeAccounts(
        integration?.id,
        integration?.type,
      ).subscribe((accounts) => {
        setIncomeAccounts(accounts);
      }, handleError);

      return () => subscription.unsubscribe();
    }
  }, [editIntegrationLateFees, integration?.id, integration?.type]);

  useEffect(() => {
    if (editIntegrationLateFees) {
      const subscription = getCategories(
        integration?.id,
        integration?.type,
      ).subscribe((categories) => {
        setQboCategories(categories);
      }, handleError);

      return () => subscription.unsubscribe();
    }
  }, [editIntegrationLateFees, integration?.id, integration?.type]);

  useEffect(() => {
    if (updateLateFeeData && editIntegrationLateFees) {
      updateLateFeeData({
        ...lateFeeData,
        third_party_account_id: selectedIncomeAccount?.id,
        third_party_category_id: selectedCategory?.id,
      });
    }
  }, [selectedCategory, selectedIncomeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div {...scope} className="instructions">
      <div className="instructions-left">
        <img
          src={cdnImage(imgSrc)}
          style={{ height: "40rem" }}
          alt={`Illustration ${imgSrc}`}
        />
      </div>
      <div className="instructions-right">
        {showLogo && (
          <img
            src={cdnImage("qbo_logo_light_horizontal.png")}
            height="60"
            alt="QuickBooks Online Logo"
          />
        )}
        {instructionsHeader && (
          <div className="instructions-header cps-wt-semibold">
            {instructionsHeader}
          </div>
        )}
        {instructionsSubHeader && (
          <div className="instructions-subheader">{instructionsSubHeader}</div>
        )}
        <div className="instructions-title cps-wt-semibold">
          {instructionsTitle}
        </div>
        {isNumberedList ? (
          <ol>
            {instructions.map((instruction, i) => (
              <li className="instruction" key={i}>
                {instruction}
              </li>
            ))}
          </ol>
        ) : isUnorderedList ? (
          <ul>
            {instructions.map((instruction, i) => (
              <li className="instruction" key={i}>
                {instruction}
              </li>
            ))}
          </ul>
        ) : (
          <div>
            {instructions.map((instruction, i) => (
              <div className="instruction" key={i}>
                {instruction}
              </div>
            ))}
          </div>
        )}
        {infoText && (
          <>
            <div className="line" />
            <div className="info-text">
              {infoIcon && (
                <div>
                  <CpIcon name={infoIcon} className="cp-mr-4" />
                </div>
              )}
              {infoText}
            </div>
          </>
        )}
        {editIntegrationLateFees && (
          <div className="qbo-sync-container">
            <div className="cp-flex">
              <div className="qbo-sync-title">
                <img
                  className="cp-mr-8"
                  src={cdnImage("qbo_logo_small_circle.svg")}
                  height={24}
                />
                QBO Sync
              </div>
            </div>
            <div className="qbo-sync-form-row">
              <label>
                Income account<span className="cp-color-app-primary">*</span>
              </label>
              <div>
                <CpSelectSingle
                  contentWidth="md"
                  data={incomeAccounts}
                  searchFilter
                  searchOnChange={setIncomeSearchValue}
                  searchValue={incomeSearchValue}
                  onChange={setSelectedIncomeAccount}
                  value={selectedIncomeAccount}
                  triggerIsBlock
                  fixedContent
                />
              </div>
            </div>
            <div className="qbo-sync-form-row">
              <label>Product category</label>
              <div>
                <CpSelectSingle
                  contentWidth="md"
                  data={formatNestedQboData(qboCategories)}
                  searchFilter
                  searchOnChange={setCategoriesSearchValue}
                  searchValue={categoriesSearchValue}
                  onChange={setSelectedCategory}
                  value={selectedCategory}
                  triggerIsBlock
                  fixedContent
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const css = k`
  .instructions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .instructions-right {
    margin-left: 5rem;
  }

  .instructions-left {
    display: flex;
    align-items: center;
  }

  .instructions-header {
    font-size: 3.2rem;
    padding: 0.8rem 0 0.8rem 1.6rem;
    font-weight: 600;
  }

  .instructions-subheader {
    padding: 0.8rem 0 0.8rem 1.6rem;
    width: 45rem;
    font-size: 1.6rem;
  }

  .instructions-title {
    font-size: 2rem;
    padding: 0.8rem 0 0.8rem 1.6rem;
    font-weight: 600;
    max-width: 45rem;
  }

  .instruction {
    padding: 0.8rem 1.6rem;
    max-width: 55rem;
    font-size: 1.6rem;
    color: var(--cp-color-app-secondary-text);
  }

  .info-text {
    font-weight: 600;
    font-size: 1.6rem;
    width: 48rem;
    margin-left: 3rem;
    display: flex;
  }

  .line {
    width: 44rem;
    margin: 3rem 0 3rem 2.5rem;
    border-bottom: 0.2rem solid var(--cp-color-app-primary-text);
  }

  .qbo-sync-container {
    background: var(--cp-color-well-l2-bg);
    border: 0.1rem solid var(--cp-color-well-border);
    border-radius: 0.8rem;
    margin-top: 1.6rem;
    margin-left: 1.6rem;
    padding: 1.6rem;
    max-width: 55rem;
  }

  .qbo-sync-form-row {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;
    margin-left: 3.2rem;
    margin-right: 1.6rem;
  }

  .qbo-sync-form-row > label {
    display: flex;
    align-items: center;
    width: 11.2rem;
  }

  .qbo-sync-title {
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
  }
`;
